import React, { Component } from "react"
import { navigate } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

import { doSignInWithEmailAndPassword } from "api/auth"

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

const INITIAL_STATE = {
  emailAddress: "",
  password: "",
  error: null,
}

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { emailAddress, password } = this.state

    doSignInWithEmailAndPassword(emailAddress, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }))
        navigate("/")
      })
      .catch(error => {
        this.setState(updateByPropertyName("error", error))
      })

    event.preventDefault()
  }

  render() {
    const { emailAddress, password, error } = this.state
    const isInvalid = password === "" || emailAddress === ""

    return (
      <Layout>
        <SEO title="Log in | Tick Tock Inc." />
        <section className="ticktockinc-container-small">
          <h1 className="is-size-4 has-text-centered has-text-weight-bold">
            Log in
          </h1>
          <br />

          <form onSubmit={this.onSubmit}>
            <div class="field">
              <label class="label">Email Address</label>
              <p class="control">
                <input
                  value={emailAddress}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("emailAddress", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Email Address"
                  className="input"
                />
              </p>
            </div>
            <div class="field">
              <label class="label">Password</label>
              <p class="control">
                <input
                  value={password}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("password", event.target.value)
                    )
                  }
                  type="password"
                  placeholder="Password"
                  className="input"
                />
              </p>
            </div>
            <br />
            <button
              className="button is-info is-rounded"
              onClick={this.onSubmit}
              disabled={isInvalid}
              type="submit"
            >
              Sign In
            </button>
            {error && <p>{error.message}</p>}
          </form>
        </section>
      </Layout>
    )
  }
}

export default Login
